input {
    outline: 0;
    background: #cacaca;
    width: 100%;
    border: 0;
    margin: 0 0 15px;
    padding: 15px;
    box-sizing: border-box;
    font-size: 14px;
    font-family: "CrimsonText-Regular" !important;
  }

  input:focus {
    background: #f2f2f2;
  }

input.reduced {
    width: 75% !important;
    margin-right: 25px !important;
  }

  @media screen and (max-width: 900px) {
    input.reduced {
        width: 100% !important;
      }
}

  textarea {
    outline: 0;
    background: #cacaca;
    width: 100%;
    border: 0;
    margin: 0 0 15px;
    padding: 15px;
    box-sizing: border-box;
    font-size: 14px;
    font-family: "CrimsonText-Regular" !important;
  }

  textarea:focus {
    background: #f2f2f2;
  }

  label {
      font-weight: 700;
      font-size: 20px;
  }

.form button {
    outline: 0;
    background: #43A047;
    width: 150px;
    border: 0;
    padding: 15px;
    color: #FFFFFF;
    font-size: 14px;
    -webkit-transition: all 0.3 ease;
    transition: all 0.3 ease;
    cursor: pointer;
  }

  .form button:hover, .form button:active, .form button:focus {
    background: #269900;
  }


  .s-btn-success {
    outline: 0;
    background: #43A047;
    width: auto;
    border: 0;
    padding: 10px 30px 10px 30px;
    color: #FFFFFF;
    font-size: 14px;
    -webkit-transition: all 0.3 ease;
    transition: all 0.3 ease;
    cursor: pointer;
  }

  .s-btn-success:hover, .s-btn-success:active, .s-btn-success:focus {
    background: #269900;
  }


  .s-btn-success-inv {
    outline: 0;
    background: white;
    width: auto;
    border: 1px solid #269900;
    padding: 10px 30px 10px 30px;
    color: #269900;
    font-size: 14px;
    -webkit-transition: all 0.3 ease;
    transition: all 0.3 ease;
    cursor: pointer;
  }

  .s-btn-success-inv:hover, .s-btn-success-inv:active, .s-btn-success-inv:focus {
    background: #269900;
    color: white;
  }