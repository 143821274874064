.top-nav {
    overflow: hidden;
    background-color: #333;
  }
  
  .top-nav a {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
  }

  .top-nav a.right {
    float: right;
    background-color: #43A047;
    color: white
  }

  .top-nav a.right:hover {
    background-color: #269900;
    text-decoration: none;
  }
  
  .top-nav a:hover {
    background-color: #ddd;
    color: black;
  }
  
  .top-nav a.nav-select {
    background-color: white;
    color: black;
  }
  
  .top-nav .icon {
    background-color: #333;
    padding: 7px 0 0 0;
    display: none;
  }

  .top-nav .icon:hover {
    background-color: #333;
  }

  .nav-l-text {
    /* font-size: 2rem;
    display: inline-block;
    font-weight: 700; */
  }

  /* When the screen is less than 600 pixels wide, hide all links, except for the first one ("Home"). Show the link that contains should open and close the topnav (.icon) */
  @media screen and (max-width: 900px) {
    .top-nav a:not(:first-child) {display: none;}
    .top-nav a.icon {
      float: right;
      display: block;
    }
  }
  /* The "responsive" class is added to the topnav with JavaScript when the user clicks on the icon. 
  * This class makes the topnav look good on small screens (display the links vertically instead of horizontally) */
  @media screen and (max-width: 900px) {
    .top-nav.responsive {position: relative;}
    .top-nav.responsive .icon {
      position: absolute;
      right: 0;
      top: 0;
    }

    .top-nav.responsive a {
      float: none;
      display: block;
      text-align: left;
    }
    
  }