/* 
* Borders 
*/
.s-border {
    border-style: solid;
    border-width: 1px;
    border-color: #4FBFE0;
}

.m-border {
    border-style: solid;
    border-width: 3px;
    border-color: #4FBFE0;
}

.l-border {
    border-style: solid;
    border-width: 5px;
    border-color: #4FBFE0;
}

.left-border {
    border-left: 6px solid #4FBFE0;
    padding: 10px 0px 10px 5px;
}

.border-green {
    border: 5px solid #33CC00;
    border-radius: 500px;
    -webkit-border-radius: 500px;
    -moz-border-radius: 500px;
}

.event-border {
    border: 2px solid white;
    padding: 50px 25px 50px 25px;
}

@media screen and (max-width: 1300px) {
    .event-border {
        padding: 20px 10px 20px 10px;
    }
}

/* 
* Display 
*/
.display-block {
    display: block;
}
.display-inline-block {
    display: inline-block;
}
.display-inline {
    display: inline;
}
.hide {
    display: none;
}

/* 
* Overflow 
*/
.overflow-div {
    overflow-x:auto;
}
.clearfix {
    overflow: auto;
}
.overflow-hidden {
    overflow: hidden;
}

/*
* Background colors 
*/
.background-xxlight-blue {
    background: #DCF2F9
}

.background-xlight-blue {
    background: #B9E5F3
}

.background-light-blue {
    background: #72CCE6
}

.background-blue {
    background: #4FBFE0
}

.background-white {
    background: white
}

.background-black {
    background: black;
}

.background-green {
    background: #43A047;
}

.background-american-red {
    background: #b32134;
}

.background-america-blue {
    background: #002868;
}

.background-grey {
    background: #e4e4e4;
}
/* 
* Table 
*/
table {
    border-collapse: collapse;
    width: 100%;
}

table caption {
    font-size: 1.5em;
    font-weight: bolder;
    margin: .5em 0 .75em;
    color: #4FBFE0;
    text-align: left;
}

.price {
    text-align: right;
}

th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #D6D6D6;
}

thead {
 background-color: #72CCE6;
 color: white;
}

tbody tr:hover {
    background-color:#DCF2F9;
}

/* 
* Card 
*/
.card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
}

@media screen and (min-width: 901px) {
    .card {
        padding: 2%;
    }
}

@media screen and (max-width: 900px) {
    .card {
        padding: 5%;
    }
}

.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

/*  
* Misc css classes manipulating objects and accessibility 
*/
/* *:focus {
    outline: #FDBB30 dotted 1px !important;
}

*::-moz-focus-inner { 
    outline: #FDBB30 dotted 1px !important;
}  */


/*  
* Alert message box 
*/
.danger-alert {
    padding: 10px;
    background-color: #FA3C57; /* Red */
    color: white;
    margin-bottom: 15px;
  }
  
  /* The close button */
  .close-btn {
    margin-left: 15px;
    color: white;
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;
  }
  
  /* When moving the mouse over the close button */
  .closebtn:hover {
    color: black;
  }


/*
*  Scroll bar
*/
 .scrollbar {
     margin-left: 30px;
     float: left;
     background: #F5F5F5;
     overflow-y: scroll;
     margin-bottom: 25px;
 }

#skinny-scroll::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #DCF2F9;
}

#skinny-scroll::-webkit-scrollbar {
	width: 6px;
	background-color: #DCF2F9;
}

#skinny-scroll::-webkit-scrollbar-thumb {
	background-color: #4FBFE0;
}

/* 
* Hover
*/
.hover:hover {
    border-style: solid;
    border-width: 1px;
    border-color: #FDBB30;
    cursor: pointer;
}
/* Hover for extra */
.extra { 
    display: none;
    margin-bottom: 5px;
}

.hover-for-extra:hover .extra {
    display: block;
}

/* 
* Misc 
*/
.strong-blur {
    filter: blur(6px);
}

.rounded {
    border-radius: 50%;
}  

.cursor-pointer {
    cursor: pointer;
}

hr {
    height: 1px;
    background-color: #D6D6D6 !important;
    border: 0 none;
    color: #D6D6D6;
}

.recaptcha {
    max-width: 304px;
    margin: 0 auto;
}

.content-none { 
    content: none;
}


/* Hero */
.hero-container-1 {
    height: 40em;
    max-width: 100%;
}

.hero-container-2 {
    height: 45em;
    max-width: 100%;
}

@media screen and (max-width: 1300px){ 
    .hero-container-2 {
        height: 50em;
    }
}

.hero-image-1 {
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url("https://firebasestorage.googleapis.com/v0/b/zellnerconsulting.appspot.com/o/media%2FBobZellner1.jpg?alt=media&token=dbe3b41b-57af-4b08-b101-93ba9fd16c6b");
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }

  .hero-image-2 {
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url("https://firebasestorage.googleapis.com/v0/b/zellnerconsulting.appspot.com/o/media%2FDL_1963FormanZellners.jpg?alt=media&token=e98e8749-a721-444a-9f6b-6c7d84cc38db");
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }

  .hero-image-3 {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://firebasestorage.googleapis.com/v0/b/zellnerconsulting.appspot.com/o/media%2FBobZellner2.jpg?alt=media&token=eccfc1b0-00f5-408b-8031-c13ca57591d5");
    height: 100%;
    background-position: 90% 15%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    
  }
  
  .hero-text-1 {
    position: absolute;
    width: 40%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-shadow: 0 0 6px black !important;
  }

  
  .hero-text-2 {
    position: absolute;
    width: 60%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-shadow: 0 0 6px black !important;
}
  
  .hero-text-1 button {
    outline: 0;
    background: #43A047;
    border: 0;
    padding: 15px 40px;
    color: #FFFFFF;
    font-size: 16px;
    -webkit-transition: all 0.3 ease;
    transition: all 0.3 ease;
    cursor: pointer;
  }
  
  .hero-text-1 button:hover {
    background-color: #269900;
  }

  .hero-text-1 p {
      font-size: 1.4em;
  }

  @media screen and (max-width: 1400px) {
    .hero-text-1 {
         width: 60%;
    }
 }

  @media screen and (max-width: 900px) {
   .hero-text-1 {
        width: 85%;
   }

   .hero-text-2 {
        width: 85%;
    }
}

.black-hr {
    display: block; 
    height: 1px;
    border: 0; 
    width: 50%;
    border-top: 1px solid black;
    margin: 20px auto;
    padding: 0; 
}


/* Quote */
.quote-card {
    color: #222222;
    text-align: center;
    padding: 20px;
    padding-left: 50px;
    box-sizing: border-box;
    box-shadow: 0 2px 4px rgba(#222222, 0.12);
    
    position: relative;

    min-height: 120px;
    
  }

  .quote-card p {
    font-size: 22px;
    line-height: 1.5;
    margin: auto;
    max-width: 80%;
    text-align: center;
  }
  
  .quote-card cite a {
    font-size: 24px;
    color: black;
    margin-top: 10px;
    font-weight: 200;
    opacity: 0.8;
  }

  .quote-card cite a:hover {
    color: #33CC00  !important;
    cursor: pointer;
}
  
  .quote-card:before {
    font-family: "CrimsonText-Regular";
    content: "“";
    position: absolute;
    top: 35px;
    left: 10px;
    font-size: 5em;
    color: rgba(#eee, 0.8);
    font-weight: normal;
  }
  
  .quote-card:after {
    font-family: "CrimsonText-Regular";
    content: "”";
    position: absolute;
    bottom: -40px;
    line-height: 100px;
    right: 50px;
    font-size: 5em;
    color: rgba(#eee, 0.8);
    font-weight: normal;
  }

  @media(max-width: 900px) {
    .quote-card {
        padding: 10px;
        padding-left: 30px;
        margin: 45px 15px 45px 15px;
    }

    .quote-card:before {
        top: 25px;
        left: -3px;
        font-size: 5em;
    }

    .quote-card:after {
      font-size: 5em;
      right: 5px;
      bottom: -75px;
    }
  }









 


