/* 
* Pixel sizing 
*/
img.xsmall {
    width: 100%;
    height: auto;
    max-width: 50px;
    padding: 5px;
}

img.small {
    width: 100%;
    height: auto;
    max-width: 100px;
}

img.medium {
    width: 100%;
    height: auto;
    max-width: 150px;
    padding: 5px;
}

img.large {
    width: 100%;
    height: auto;
    max-width: 300px;
    padding: 5px;
}

img.xlarge {
    width: 100%;
    height: auto;
    max-width: 450px;
    padding: 5px;
}

img.xxlarge {
    width: 100%;
    height: auto;
    max-width: 550px;
}

img.xxxlarge {
    width: 100%;
    height: auto;
    max-width: 650px;
}


/* 
* em sizing
*/
img.xs-em {
    height: 0.75em;
}
img.s-em {
    height: 1em;
}

img.m-em {
    height: 1.25em;
}

img.l-em {
    height: 1.75em;
}

img.xl-em {
    height: 2em;
}

/* Video */
.video-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


/* 
* Misc 
*/
.responsive {
    max-width: 100%;
    height: auto;
}

.home-icon {
    width: 100%;
    height: auto;
    max-width: 60px;
}